import _ from 'lodash';

import { DateBreakdown, NumericBreakdown } from '../../classes/Breakdowns';
import {
  CategoricalField,
  DateField,
  NumericField,
  ScoreField
} from '../../classes/MetadataFields';
import {
  defaultDateInterval,
  isBreakdownFieldDisabled,
  isDateIntervalInvalid
} from '../../utils/breakdownValidityUtils';
import { filterBreakdownField } from '../../utils/filterBreakdown';
import { getConceptsToVisualizeParams } from './getConceptsToVisualizeParams';

export function volumeSearchDefaults(params, store) {
  const conceptsToVisualizeSubQuery = getConceptsToVisualizeParams(
    params,
    store,
    { concepts: 'prevalent' }
  );

  const matchType = ['exact', 'total'].includes(params.match_type)
    ? params.match_type
    : 'total';

  const outlier_mode = ['only', 'include'].includes(params.outlier_mode)
    ? params.outlier_mode
    : null;

  const conceptColor =  ['true', 'false'].includes(params.concept_color)
    ? params.concept_color
    : 'false';

  const sortby = [
    'name-asc',
    'name-desc',
    'matches-desc',
    'matches-asc'
  ].includes(params.sortby)
    ? params.sortby
    : 'matches-desc';

  const breakdownField = getFilteredBreakdownField(
    params.breakdown,
    store.metadata,
    params.filter
  );
  const breakdown =
    breakdownField && !isBreakdownFieldDisabled(breakdownField)
      ? params.breakdown
      : null;

  const interval =
    breakdownField instanceof DateField
      ? DateBreakdown.INTERVALS.includes(params.interval) &&
        !isDateIntervalInvalid(breakdownField, params.interval)
        ? params.interval
        : defaultDateInterval(breakdownField)
      : (breakdownField instanceof NumericField ||
          breakdownField instanceof ScoreField) &&
        (parseFloat(params.interval) ||
          NumericBreakdown.getIntervals(breakdownField).default);

  const normalized = (['true', 'false'].includes(params.normalized)
    ? params.normalized === 'true'
    : breakdownField instanceof CategoricalField ||
      breakdownField instanceof ScoreField
  ).toString();

  return {
    ...params,
    ...conceptsToVisualizeSubQuery,
    match_type: matchType,
    sortby,
    breakdown,
    interval: breakdown && interval ? interval : null,
    normalized: breakdown ? normalized : null,
    concept_color: conceptColor,
    outlier_mode
  };
}

function getFilteredBreakdownField(name, metadata, filter) {
  const field = _.find(metadata, { name });
  return filterBreakdownField(field, filter);
}
