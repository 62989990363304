export const featureFlagsDefaults = Object.freeze({
  boolean_search: true,
  boolean_search_toggle: false,
  white_color: false,
  color_by_concept_list: false,
  doc_viewer: false,
  skip_sentiment: false,
  external_login: false,
  custom_branding: false,
  gen_ai_poc: false,
  less_clicks_btn: false,
  stream_data: false,
  stream_data_test_category: false,
  helios_custom: false,
  helios_features: true,
  new_scrapers: false
});

let featureFlags = featureFlagsDefaults;

export function getFeatureFlags() {
  return featureFlags;
}

export function setFeatureFlags(newFeatureFlags) {
  featureFlags = newFeatureFlags;
}
